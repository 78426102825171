<template>
    <div class="card-tail flex justify-end items-center space-x-2 border-b">
        <el-checkbox v-model="includeCompleted" @change="updateFilters">Inkludera avslutade</el-checkbox>
        <div class="w-[200px]">
            <DateInputField ref="dateInputField" @change="inputChanged" placeholder="Slutdatum" :pre-selected-value="endDate" />
        </div>
        <div>
            <el-button type="warning" @click="clearFilters" class="py-[6px] px-[9px]">
                <i class="fas fa-trash" />
            </el-button>
        </div>
    </div>
</template>
<script>
import queryString from "query-string";

export default {
    data() {
        return {
            endDate: "",
            includeCompleted: false,
        };
    },

    components: {
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { filterEndDate } = queryString.parse(location.search);
            const { includeCompleted } = queryString.parse(location.search);
            this.endDate = filterEndDate || "";
            this.includeCompleted = includeCompleted === "true" ? true : false;
        },

        inputChanged(value) {
            this.endDate = value;
            this.updateFilters();
        },

        filtersChangedImmediately() {
            clearInterval(this.timer);
            this.updateFilters();
            this.$emit("change");
        },

        async filtersChanged() {
            this.updateFilters();
            this.emitChange();
        },

        async updateFilters() {
            const query = queryString.parse(location.search);
            query.filterEndDate = this.endDate;
            query.includeCompleted = this.includeCompleted;
            await this.$router.replace({ query }).catch(() => {});
            this.$emit("change", this.endDate, this.includeCompleted);
        },

        emitChange() {
            clearInterval(this.timer);
            this.timer = setTimeout(() => {
                this.$emit("change");
            }, 1000);
        },

        clearFilters() {
            this.reset();
            this.$emit("change");
        },

        async reset() {
            this.endDate = "";
            this.includeCompleted = false;
            this.$refs.dateInputField.reset();
            const query = queryString.parse(location.search);
            delete query.filterEndDate;
            delete query.includeCompleted;
            await this.$router.replace({ query }).catch(() => {});
        },
    },
};
</script>
